import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    li{
        font-weight: 400;
        width: 100%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Bigsub = styled.h2`
    font-weight: 700;
    font-size: 28px;
    color: var(--green-text);
`

const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "diensticonen/recycling.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        anotherImage: file(relativePath: { eq: "recycling/recycling.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        softdes: file(relativePath: { eq: "datavernietiging/softwarematige_dataverwijdering.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mechdes: file(relativePath: { eq: "datavernietiging/mechanische_dataverwijdering.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    `)
    const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />,<Img className={className} fluid={data.anotherImage.childImageSharp.fluid}/>, <Img className={className} fluid={data.softdes.childImageSharp.fluid} />, <Img className={className} fluid={data.mechdes.childImageSharp.fluid} />]
    return image[something];
    }


const Duurzaamheid = () => (

  <Layout>
    <SEO title="Duurzaamheid" />
    <PageHeader title="Duurzaamheid">
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink href="/informatie" onClick={handleClick}>
            Informatie
          </Crumblink>
          <Crumblink aria-current="page" href="/informatie/duurzaamheid/" onClick={handleClick}>
            Duurzaamheid
          </Crumblink>
        </Styledcrumbs>
    </PageHeader>
        <Maincontent>
            <Containerpad>

                <Vertpad>

                </Vertpad>
                <Vertpad>
                    <Subheader>Visie op duurzaamheid en circulaire economie</Subheader>
                    <Paragraph>
                        Duurzaamheid is vandaag de dag een steeds belangrijker wordend onderwerp.<br/> Over
                        circulaire economie leest u iedere dag wel iets in de krant.<br/> Circulaire economie houdt in
                        dat producten langer blijven circuleren, voordat ze vernietigd worden.
                    </Paragraph>
                    <Paragraph>
                        Ons huidig lineaire economisch model is gebaseerd op &#39;groei&#39;.<br/> We zijn gaan geloven dat
                        een groeiende economie belangrijker is dan een duurzame stabiele economie.<br/> Wat
                        hebben de volgende generaties aan “onze” maximale economische groeipercentages en
                        bedrijfswinsten als zij in 2050 te kampen hebben met een opgewarmde aarde,
                        zeespiegelstijging, extreme hitte, gesmolten poolkappen, overstromingen, schade aan
                        biodiversiteit en uitgestorven diersoorten door klimaatverandering en milieuvervuiling.
                    </Paragraph>
                    <Paragraph>
               
                        Wij zijn ervan overtuigd dat als we doorgaan met ons huidige economisch groeimodel,
                        het natuurlijk evenwicht in de toekomst in gevaar zal komen door.<br/> Als onze generatie
                        niets verandert zullen grondstoffen, energiebronnen en voedsel- en watervoorziening
                        zwaar onder druk komen te staan, met grote gevolgen voor de gezondheid en veiligheid
                        van de mens.
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Subheader>Het effect van computers en smartphones</Subheader>
                    <Paragraph>
                    Natuurlijk, computers en smartphones hebben ons leven op verschillende vlakken
verrijkt.<br/> We zijn ten allen tijde bereikbaar en we kunnen werkelijk overal in de wereld onze
vrienden en familie bereiken.<br/> Wereldwijde bereikbaarheid en digitale globalisering! Ook
kunnen we ontspannen met vermakelijke apps en het bekijken van sociale media.<br/> Klinkt
allemaal geweldig, maar er zit ook een keerzijde aan deze schitterende ontwikkeling...<br/>
Milieutechnisch is de jaarlijkse productie van hardware en smartphones een zware
aanslag op ons milieu.<br/> De dreiging schuilt met name in de uitstoot van broeikasgassen
en mijnbouw naar schaarse delfstoffen, die noodzakelijk zijn voor de productie van
allerlei elektronische apparaten.
                    </Paragraph>

                </Vertpad>
                <Vertpad>
                    <Subheader>Hoe draagt Green Digital bij aan duurzaamheid?</Subheader>
                    <Paragraph>
                        Wij geloven in de transitie van een lineaire economie naar een circulaire economie.<br/> In de
                        circulaire economie worden nauwelijks nieuwe grondstoffen gedolven, wordt geen afval
                        geproduceerd, omdat grondstoffen en producten steeds opnieuw worden gebruikt. Wij
                        leveren een bijdrage aan de circulaire economie in het hergebruik van ICT apparatuur. Wij
                        nemen de verantwoordelijkheid voor de afvoer van uw ICT apparatuur. Wij ontzorgen uw
                        organisatie door een veilig, secuur, transparant en betrouwbaar verwerkingsproces en
                        garanderen u maximaal hergebruik en restwaarde!
                    </Paragraph>

                </Vertpad>
               
                
            </Containerpad>

        </Maincontent>
    <Padding/>
  </Layout> 
)

export default Duurzaamheid


